import React from 'react';
import Layout from "../../composite/layout";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import BookingSuccessResult from "../../../assets/images/booking-success-result.svg";
import BookingErrorResult from "../../../assets/images/booking-error-result.svg";
import BookingInformationCard from "../../composite/booking-information-card";
import Button from "../../atomic/button";
import {useLocation, useNavigate} from "react-router-dom";
import {InfoIcon, LeftArrowIcon, RightArrowIcon} from "../../../assets/icons";
import {replacePath} from "../../../utils/navigations";

const BookingResult = () => {
    const navigate = useNavigate();
    const {bookingResult, requestBookingInformation} = useGlobalContext();
    const result = bookingResult !== undefined
    const location = useLocation();

    return (
        <Layout>
            <>
                {result &&
                    <p className={'text-white text-center bg-purple-dark p-2 w-full text-[14px]'}>
                        لطفا از این صفحه اسکرین‌شات بگیرید
                    </p>
                }
                <div className={'flex flex-col items-center text-right my-auto gap-6 p-5 pt-10'}>
                    <img src={result ? BookingSuccessResult : BookingErrorResult} alt={'result'}/>
                    <div
                        className={`flex flex-col gap-1 w-full items-center ${result ? 'text-green' : 'text-red'}`}
                    >
                        <p className={'text-right'}>{`${bookingResult?.name ?? requestBookingInformation?.name} عزیز!`}</p>
                        <p className={'text-right'}>{result ? 'نوبت شما با موفقیت ثبت شد' : 'ثبت نوبت شما با خطا مواجه شد'}</p>
                    </div>

                    {result && bookingResult?.successMessage &&
                        <div className={'flex gap-2 bg-[#E1EFFF] rounded p-4 w-full'}>
                            <InfoIcon color={"#2066B5"}/>
                            <p className={'text-[#2066B5] text-[12px] w-full md:max-w-[40vw]'}>
                                {bookingResult?.successMessage}
                            </p>
                        </div>
                    }

                    <BookingInformationCard
                        mode={'booking-result'}
                        data={bookingResult}
                        resultStatus={result}
                    />
                    <div className={'flex flex-col gap-1'}>
                        {!result &&
                            <Button
                                title={'بازگشت به صفحه ثبت نوبت'}
                                onClick={() => navigate(-1)}
                                variety={'secondary'}
                                rightIcon={<RightArrowIcon/>}
                                customClassName={'h-[4vh]'}
                            />
                        }
                        <Button
                            title={'برو به صفحه نوبت‌های من'}
                            onClick={() =>
                                navigate(replacePath(location.pathname, 'my-appointment-bookings', 2))
                            }
                            variety={!result ? 'tertiary' : 'secondary'}
                            leftIcon={<LeftArrowIcon color={!result ? "#92395C" : "#501A46"}/>}
                            customClassName={`h-[4vh] ${!result && 'bg-transparent hover:bg-transparent text-[#92395C]'}`}
                        />
                    </div>
                </div>
            </>
        </Layout>
    )
}

export default BookingResult;
