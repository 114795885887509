import {
    useLazyGetDataQuery,
    usePatchDataMutation,
    usePostDataMutation,
} from "../api/services/apiSlice";
import {useGlobalContext} from "../contexts/GlobalContext";

export default function useBooking() {
    const [postApi, responsePostApi] = usePostDataMutation();
    const [getApi, responseGetApi] = useLazyGetDataQuery();
    const [patchApi, responsePatchApi] = usePatchDataMutation();

    const {setBookingInformation, setBookingResult, setRequestBookingInformation} = useGlobalContext();

    const saveBooking = async (requestBody: BookingInformationRequestBody) => {
        try {
            setRequestBookingInformation({
                name: requestBody.name,
                bookingPageSlug: requestBody?.bookingPageSlug,
                mobile: requestBody?.mobile,
                serviceId: requestBody?.serviceId,
                datetimeFrom: requestBody?.datetimeFrom,
                datetimeTo: requestBody?.datetimeTo
            });

            let response = await postApi({
                url: "booking/save",
                body: requestBody
            });

            if(!response?.error){
                setBookingResult(response.data);
                return true;
            }
            setBookingResult(undefined);
            return false;

        } catch (error) {
            setBookingResult(undefined);
            console.error(error);
            return false;
        }
    };

    const cancelBooking = async (id: string, selected_booking: BookingType, slug: string | undefined) => {
        try {
            let response = await patchApi({
                url: `booking/cancel/${id}`,
                body: {
                    'mobile': selected_booking.mobile,
                    'serviceId': selected_booking.serviceId,
                    'datetimeFrom': selected_booking.datetimeFrom,
                    'bookingPageSlug': slug
                }
            });
            return (response?.data && response?.data?.canceled === true)
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getBookingInformation = async (slug: string, phoneNumber: string) => {
        try {
            let response = await getApi({
                url: `booking/info?mobile=${phoneNumber}&slug=${slug}`,
            });

            if (response?.isSuccess) {
                setBookingInformation(response?.data);
                return response?.data?.bookings?.length > 0;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return {
        getBookingInformation,
        responsePostApi,
        responseGetApi,
        responsePatchApi,
        saveBooking,
        cancelBooking,
    };
}
