import React, {useEffect, useState} from 'react';
import {extractAndConvertDateToShamsi, extractAndConvertTimeToShamsi} from "../../../utils/convertors";
import {MapPinIcon} from "../../../assets/icons";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import {useNavigate, useParams} from "react-router-dom";

const BookingInformationCard = (
    {
        mode, data, resultStatus
    }:
        {
            mode: 'booking-result' | 'payment-result' | 'canceling';
            data: BookingType | undefined;
            resultStatus: boolean;
        }) => {
    const navigate = useNavigate();
    const {slug} = useParams();

    const {bookingInformation, requestBookingInformation} = useGlobalContext();
    const [selectedService, setSelectedService] = useState<ServiceType>();
    useEffect(() => {
        if (requestBookingInformation){
            console.log(            bookingInformation?.services?.find((service) => service.id === requestBookingInformation?.serviceId)
            )
            setSelectedService(
                bookingInformation?.services?.find((service) => service.id === requestBookingInformation?.serviceId)
            );
        }
    }, [requestBookingInformation]);

    useEffect(() => {
        if(!data && !requestBookingInformation){
            try {
                navigate(`/${slug}/appointment-booking`)
            } catch (e) {
                console.error(e);
            }
        }
    }, []);

    return (
        <div className={'flex flex-col bg-[#f7f7f9] rounded-lg w-full'}>
            <div className={'flex flex-col gap-3 p-6 text-[14px]'}>
                {['booking-result', 'payment-result'].includes(mode) && resultStatus &&
                    <div className={'flex justify-between'}>
                        <p className={'text-gray-dark'}>کد نوبت</p>
                        <p className={'text-gray-dark'}>{data?.id}</p>
                    </div>
                }
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>نام</p>
                    <p className={'text-gray-dark'}>{data?.name ?? requestBookingInformation?.name}</p>
                </div>
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>خدمات زیبایی</p>
                    <p className={'text-gray-dark'}>{data?.serviceName ?? selectedService?.name ?? ''}</p>
                </div>
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>مبلغ</p>
                    <p className={'text-gray-dark'}>{data?.price ?? selectedService?.price ?? ''} تومان</p>
                </div>
                {mode === 'payment-result' &&
                    <div className={'flex justify-between'}>
                        <p className={'text-gray-dark'}>کد پیگیری</p>
                        <p className={'text-gray-dark'}>{data?.id}</p>
                    </div>
                }
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>روز</p>
                    <p className={'text-gray-dark'}>{extractAndConvertDateToShamsi(data?.datetimeFrom ?? requestBookingInformation?.datetimeFrom ?? '')}</p>
                </div>
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>ساعت</p>
                    <div className={'flex w-fit gap-2'}>
                        <p className={'text-gray-light'}>از</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeFrom ?? requestBookingInformation?.datetimeFrom ?? '')}</p>
                        <p className={'text-gray-light'}>تا</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeTo ?? requestBookingInformation?.datetimeTo ?? '')}</p>
                    </div>
                </div>
            </div>
            {mode === 'booking-result' && resultStatus && <div className={'flex justify-start gap-2 border-t p-6'}>
                <MapPinIcon/>
                <p className={'text-right text-[12px]'}>{data?.address}</p>
            </div>}
        </div>
    )
}

export default BookingInformationCard;
