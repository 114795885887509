import React from 'react';
import Input from "../../../atomic/input";
import Button from "../../../atomic/button";
import BookingInformationCard from "../../../composite/booking-information-card";

const CancelReservationRequestModal = ({onCloseModal, selectedBookingToCancel, onClickCancelBookingRequestButton, loading}: {
    onCloseModal: () => void;
    selectedBookingToCancel: BookingType | undefined;
    onClickCancelBookingRequestButton: (bookingId: string, selected_booking: BookingType) => void;
    loading: boolean;
}) => {
    const [bookingId, setBookingId] = React.useState<string>("");

    return (
        <>
            <div className={'flex flex-col items-center justify-center m-auto md:w-[30vw] gap-4 p-5'}>
                <p className={'text-gray-dark w-full text-right'}>جهت لغو نوبت، شماره نوبت خود را وارد کنید:</p>
                <Input
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                    label={'کد نوبت *'}
                    placeholder={'کد نوبت را وارد کنید'}
                    type={'number'}
                />
                <BookingInformationCard resultStatus={true} mode={'canceling'} data={selectedBookingToCancel} />

            </div>
            <div className={'flex justify-center items-center gap-4 bg-gray-100 w-full py-4'}>
                <div className={'flex gap-2 md:w-[30vw] justify-center'}>
                    <Button
                        title={'بستن'}
                        onClick={() => onCloseModal()}
                        variety={'secondary'}
                        customClassName={'!min-w-[163px] h-[4vh]'}
                    />
                    <Button
                        title={'لغو نوبت'}
                        disabled={bookingId === ""}
                        onClick={() => selectedBookingToCancel && onClickCancelBookingRequestButton(bookingId, selectedBookingToCancel)}
                        customClassName={'!min-w-[163px] h-[4vh]'}
                        colorType={'reject'}
                        loading={loading}
                    />
                </div>
            </div>
        </>
    )
}

export default CancelReservationRequestModal;
