import React from 'react';

const Chip = (
    {
        title,
        price,
        isSelected = false,
        onClick = null,
        disabled = false,
    }: {
        title: string | null;
        price: number | null;
        isSelected?: boolean;
        onClick: React.MouseEventHandler<HTMLDivElement> | null;
        disabled?: boolean;
    }
) => {
    if (!price) return null;
    return (
        <div
            className={`flex px-2 py-1 gap-1 my-2 ${onClick ? 'cursor-pointer' : ''} items-center rounded-full text-[14px] ${disabled ? 'bg-[#F5F6F7] border border-[#515660] text-[#515660]' : isSelected ? 'bg-purple-dark text-white' : 'bg-purple-light border border-purple-dark text-purple-dark'}`}
            onClick={onClick ?? undefined}
        >
            <p>{title} {price?.toLocaleString()}</p>
            <div className={'text-[8px]'}>
                {`${price ? 'تومان' : ''}`}
            </div>
        </div>
    )
}

export default Chip;
