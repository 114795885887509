import React, {useState} from 'react';

import DatePicker, {DateObject} from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import type{Value} from "react-multi-date-picker"
import {CalendarIcon} from "../../../assets/icons";
import Input from "../input";
import './styles.css';
import {convertPersianToEnglishDigits} from "../../../utils/convertors";

const CustomDatePicker = ({value, setValue, fullDates, emptyDates}: {
    value: Value; setValue: React.Dispatch<React.SetStateAction<Value>>; fullDates?: string[]; emptyDates?: string[];
}) => {
    const giveMeDateStatus = (date: DateObject) => {
        const formattedDate = date.format("YYYY/MM/DD");
        return fullDates?.includes(formattedDate) ? 'full' : emptyDates?.includes(formattedDate) ? 'empty' : 'disabled';
    };
    return (
        <DatePicker
            value={value}
            // onChange={setValue}
            onChange={(newValue) => {
                // Update the value in English format
                const stringValue = newValue?.toString(); // Get the string representation of the date
                setValue(convertPersianToEnglishDigits(stringValue ?? '')); // Store value with English digits
            }}
            calendar={persian}
            locale={persian_fa}
            className="purple"
            arrow={false}
            shadow={false}
            mapDays={({ date }) => {
                const dateStatus = giveMeDateStatus(date);
                if (dateStatus === 'full') {
                    return {
                        disabled: true,
                        style: { color: "red" },
                        children: (
                            <div>
                                {date.day} {/* Display day number */}
                                <div style={{ fontSize: "8px", color: "red" }}>پر شد</div> {/*} Custom label "full" */}
                            </div>
                        ),
                    };
                } else if (dateStatus === 'disabled') {
                    return {
                        disabled: true,
                        style: { color: "#C3C8D2" },
                    }
                }
            }}
            placeholder="Select a date"
            render={(value, openCalendar) => {
                return (
                    <Input
                        label={'روز *'}
                        placeholder={'سال/ماه/روز'}
                        rightIcon={<CalendarIcon/>}
                        value={value?.toString() ?? ''}
                        onChange={(e) => {
                        }}
                        readOnly={true}
                        onClick={() => openCalendar()}
                    />
                )
            }}
        />
    )
}

export default CustomDatePicker;
