import React, {useEffect, useState} from 'react';
import Button from "../../../atomic/button";
import {LeftArrowIcon} from "../../../../assets/icons";
import TimeslotItem from "../../../atomic/timeslot-item";
import useTimeSlots from "../../../../hooks/useTimeSlots";
import {useParams} from "react-router-dom";
import {convertJalaliToGregorian} from "../../../../utils/convertors";
import Loading from "../../../atomic/loading";

const TakeTimeSlotModal = ({onCloseModal, day, serviceId, selectedTimeSlot, setSelectedTimeSlot}: {
    onCloseModal: () => void;
    day: string;
    serviceId: number | undefined;
    setSelectedTimeSlot: (value: TimeSlotType) => void;
    selectedTimeSlot: TimeSlotType | undefined;
}) => {
    const {slug} = useParams();
    const {getTimeSlots, responseGetApi} = useTimeSlots();

    const [timeSlots, setTimeSlots] = useState<TimeSlotsType>([]);

    const getAllTimeSlots = async () => {
        if (slug && day && serviceId) {
            let result = await getTimeSlots(slug, convertJalaliToGregorian(day), serviceId);
            if (result?.status) {
                setTimeSlots(result?.data);
            }
        }
    }

    useEffect(() => {
        getAllTimeSlots()
    }, []);

    return (
        <>
            <div className={'flex flex-col items-center text-right my-auto gap-4 p-5 pt-10'}>
                <p className={'text-gray-dark text-right'}>ساعت نوبت خود را انتخاب کنید:</p>
                {responseGetApi?.isLoading ? <Loading type={'dark'}/> : <div className={'flex flex-col gap-2 overflow-y-auto h-[20vh]'}>
                    {timeSlots?.map(timeSlot =>
                        <TimeslotItem
                            isSelected={timeSlot.start === selectedTimeSlot?.start}
                            startTime={timeSlot?.start}
                            endTime={timeSlot?.end}
                            isAvailable={timeSlot?.isBooked}
                            onClick={() => setSelectedTimeSlot(timeSlot)}
                        />
                    )}
                </div>}
            </div>
            <div className={'flex justify-center items-center text-right gap-4 bg-gray-100 w-full py-4'}>
                <Button
                    title={'انتخاب ساعت'}
                    leftIcon={<LeftArrowIcon/>}
                    onClick={() => onCloseModal()}
                    customClassName={'h-[4vh]'}
                />
            </div>
        </>
    )
}

export default TakeTimeSlotModal;
