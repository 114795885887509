import React, {useEffect, useState} from 'react';
import Title from "../../atomic/title";
import Layout from "../../composite/layout";
import Input from "../../atomic/input";
import Description from "../../atomic/description";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import Chip from "../../atomic/chip";
import {DownArrowIcon, RightArrowIcon} from "../../../assets/icons";
import ActionBar from "../../composite/action-bar";
import TakeTimeSlotModal from "../../screen/modals/take-timeslot-modal";
import Modal from "../../composite/modal";
import useBooking from "../../../hooks/useBooking";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    combineDateAndTime,
    convertJalaliToGregorian,
    extractAndConvertDateToShamsi
} from "../../../utils/convertors";
import CustomDatePicker from "../../atomic/date-picker";
import type {Value} from "react-multi-date-picker";
import Loading from "../../atomic/loading";
import {replacePath} from "../../../utils/navigations";

const AppointmentBooking = () => {
    const {bookingInformation} = useGlobalContext();
    const {getBookingInformation, saveBooking, responsePostApi, responseGetApi} = useBooking();
    const {slug} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [name, setName] = useState("");
    const [date, setDate] = useState<Value>(null);
    const [service, setService] = useState<ServiceType>();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotType>()
    const [displayTimeSlotPickerModal, setDisplayTimeSlotPickerModal] = useState(false);

    const onClickSubmitBookingButton = async () => {
        try {
            let gregorianDate = convertJalaliToGregorian(date ? date.toString() : '');
            let phoneNumber = localStorage?.getItem('phoneNumber');

            if (phoneNumber) {
                let result = await saveBooking({
                    name,
                    mobile: phoneNumber,
                    serviceId: service?.id,
                    datetimeFrom: combineDateAndTime(gregorianDate, selectedTimeSlot?.start),
                    datetimeTo: combineDateAndTime(gregorianDate, selectedTimeSlot?.end),
                    bookingPageSlug: slug,
                });
                navigate('result');
            } else {
                console.error('phone number not found');
            }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!bookingInformation && slug) {
            let phoneNumber = localStorage?.getItem('phoneNumber');
            if (!phoneNumber) {
                navigate('/')
            } else {
                getBookingInformation(slug, phoneNumber);
            }
        }
    }, [bookingInformation]);

    return (
        <Layout>
            <>
                <div className={'flex flex-col text-right mb-auto mt-10 gap-8 overflow-y-scroll h-[70vh]'}>
                    <div className={'flex gap-4 items-center'}>
                        <button onClick={() => navigate(replacePath(location.pathname, 'my-appointment-bookings', 1))}>
                            <RightArrowIcon/>
                        </button>
                        <Title text="ثبت نوبت"/>
                    </div>
                    <div className={'flex flex-col gap-2'}>
                        <Input
                            label={'نام *'}
                            placeholder={'نام خود را وارد کنید'}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className={'flex flex-col gap-2'}>
                        <Title text="خدمات زیبایی"/>
                        <Description text={'از خدمات زیر، خدمت مورد نیاز خود را انتخاب کنید:'}/>
                        <div className={'flex gap-3'}>
                            {responseGetApi?.isLoading ?
                                <div className={'flex w-full justify-center'}>
                                    <Loading type={'dark'}/>
                                </div> :
                                bookingInformation?.services?.map((item, index) => (
                                    <Chip
                                        title={item?.name}
                                        price={item?.price}
                                        isSelected={item.name === service?.name}
                                        key={index}
                                        onClick={() => setService(item)}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className={'flex flex-col gap-3'}>
                        <Title text="زمان نوبت"/>
                        <Description text={'زمان مورد نظر خود را انتخاب کنید:'}/>
                        <CustomDatePicker
                            value={date}
                            setValue={setDate}
                            fullDates={
                                bookingInformation?.workdays?.filter(
                                    (item) => item?.isBooked
                                )?.map(
                                    (item) => (extractAndConvertDateToShamsi(item?.date))
                                )
                            }
                            emptyDates={
                                bookingInformation?.workdays?.filter(
                                    (item) => !item?.isBooked
                                )?.map(
                                    (item) => (extractAndConvertDateToShamsi(item?.date))
                                )
                            }
                        />
                        <Input
                            label={'ساعت *'}
                            placeholder={'مثلا 14:00'}
                            leftIcon={<DownArrowIcon/>}
                            value={selectedTimeSlot?.start ?? ''}
                            disabled={date === "" || !service}
                            onChange={(e) => {
                            }}
                            readOnly={true}
                            onClick={() => setDisplayTimeSlotPickerModal(true)}
                        />
                    </div>
                    <ActionBar
                        price={1}
                        hasPayment={bookingInformation?.hasPayment ?? false}
                        onClickSubmitBookingButton={onClickSubmitBookingButton}
                        loading={responsePostApi?.isLoading}
                        disabled={name === "" || !service || !date || !selectedTimeSlot}
                    />
                </div>
                <Modal isOpen={displayTimeSlotPickerModal} onClose={() => setDisplayTimeSlotPickerModal(false)}>
                    <TakeTimeSlotModal
                        day={date ? date?.toString() : ''}
                        serviceId={service?.id}
                        onCloseModal={() => setDisplayTimeSlotPickerModal(false)}
                        selectedTimeSlot={selectedTimeSlot}
                        setSelectedTimeSlot={setSelectedTimeSlot}
                    />
                </Modal>
            </>
        </Layout>
    )
}

export default AppointmentBooking;
