import React from 'react';
import Chip from "../../atomic/chip";
import Button from "../../atomic/button";
import {CalendarIcon, CancelRedCircleIcon, ClockCircleIcon, InfoIcon} from "../../../assets/icons";
import {extractAndConvertDateToShamsi, extractAndConvertTimeToShamsi} from "../../../utils/convertors";

const BookedServiceCard = (
    {
        data,
        onClickCancelReservationButton
    }:
        {
            data: BookingType; onClickCancelReservationButton: React.MouseEventHandler<HTMLButtonElement>
        }
) => {
    return (
        <div className={`flex flex-col border rounded ${data?.canceled ? 'border-[#DEE0E5] bg-[#EDEEF0]' : 'bg-purple-superLight border-purple-light'}`}>
            <div className={'flex flex-col gap-3 p-3 text-sm'}>
                <div className={'flex justify-between items-center'}>
                    <div className={'flex gap-2'}>
                        <p className={'text-gray-light'}>نوبت برای</p>
                        <p>{data?.name}</p>
                    </div>
                    {data?.canceled && <div>
                        <div
                            className={`flex px-2 py-1 gap-2 items-center rounded-full text-[14px] text-white bg-[#9EA4B0]`}
                        >
                            <InfoIcon color={'white'}/>
                            لغو شده
                        </div>
                        <Chip price={data?.price} title={''} onClick={null}/>
                    </div>}
                </div>
                <div className={'flex items-center gap-2'}>
                    <p>{data?.serviceName}</p>
                    <Chip disabled={data?.canceled} price={data?.price} title={null} onClick={null}/>
                </div>
                <div className={'flex justify-between'}>
                    <div className={'flex gap-2 items-center'}>
                        <CalendarIcon stroke={"1.2"} color={data?.canceled ? '#9EA4B0' : '#92395c'}/>
                        <p>{extractAndConvertDateToShamsi(data?.datetimeFrom)}</p>
                    </div>
                    <div className={'flex gap-2 items-center'}>
                        <ClockCircleIcon color={data?.canceled ? '#9EA4B0' : '#92395c'}/>
                        <p className={'text-gray-light'}>از</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeFrom)}</p>
                        <p className={'text-gray-light'}>تا</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeTo)}</p>
                    </div>
                </div>
            </div>
            {!data?.canceled && <div className={'flex bg-white rounded-b justify-start'}>
                <Button
                    title={'لغو نوبت'}
                    rightIcon={<CancelRedCircleIcon/>}
                    onClick={onClickCancelReservationButton}
                    variety={'tertiary'}
                    customClassName={'min-w-fit text-[#FF4D4F] bg-transparent hover:bg-transparent'}
                />
            </div>}
        </div>
    )
}

export default BookedServiceCard;
